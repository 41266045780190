.preload {
    width: 100%;
    height: 100%;
    /* background-image: url("../images/bg-new2.jpg"); */
    /* background-color: white; */
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

}

/* .preload-front {
    width: 100%;
    height: 100%;
    /* background-image: url("../images/bg-new2.jpg"); */
    /* background-color: rgba(225,225,225,0.3); */
    /* background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

} */
  
.preload-content {
    width: 300px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
}

.loader-percentage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #00a9a7;
    font-size: 2rem;
}

.loader{
    width: 200px;
    margin-left: 50%;
    transform: translate(-50%,0);
}

.loader-black-text{
    color: black;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.loader-white-text{
    background-color: #f24f00;
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    width: 200px;
    margin-left: 50%;
    transform: translate(-50%,0);
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  /* 
  #002f4b,#dc4225 
  Convert HEX to RGBA - http://hex2rgba.devoth.com/
  */
.preload:before {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;

    background: rgba(255,255,255,0.36);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0.36) 0%, rgba(0,169,166,0.8) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0.36) 0%, rgba(0,169,166,0.) 100%);
    background: -o-linear-gradient(0deg, rgba(255,255,255,0.36) 0%, rgba(0,169,166,0.8) 100%);
    background: -ms-linear-gradient(0deg, rgba(255,255,255,0.36) 0%, rgba(0,169,166,0.8) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,0.36) 0%, rgba(0,169,166,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00a9a6', GradientType=1 );
    }

.preload-logo {
    width: 300px;
    height: 70px;
    margin: 150px auto 50px auto;
    font-size: 50px;
    text-shadow: -1px 2px 2px #000;
    text-align: center;
    color: black;
}

.loader-frame {
    width: 170px;
    height: 170px;
    margin: auto;
    position: relative;
}

.loader1, .loader2 {
    position: absolute;
    border: 5px solid transparent;
    border-radius: 50%;
}

.loader1 {
    width: 170px;
    height: 170px;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    animation: clockwisespin 2s linear 3;
}

.loader2 {
    width: 150px;
    height: 150px;
    border-left: 5px solid #00a9a7;
    border-right: 5px solid #00a9a7;
    top: 10px; left: 10px;
    animation: anticlockwisespin 2s linear 3;
}

@keyframes clockwisespin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes anticlockwisespin {
    from {transform: rotate(0deg);}
    to {transform: rotate(-360deg);}
}

@keyframes fadeout {
    from {opacity: 1;}
    to {opacity: 0;}
}