/****************************************
*************** VARIABLES ***************
****************************************/

:root {
    --sd-primary-color: #00a9a7;
}

@font-face {
    font-family: "RobotoRegular";
    src: url("../fonts/roboto-regular.ttf") format('truetype');
}
/****************************************
********** DEFAULT HTML 5 TAGS **********
****************************************/

body {
    color: black;
    font-family: RobotoRegular;
    font-size: 1em;
}

header {
    background-image: url("../images/bg.jpg"); 
    height: 820px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
}

header.home{
    background-image: url("../images/bg.jpg"); 
    background-position: center 35%; 
    background-repeat: no-repeat; 
    background-size: cover; 
    -webkit-transition:all 2s ease;
    -moz-transition:all 2s ease;
    -o-transition:all 2s ease;
    -ms-transition:all 2s ease;
    transition:all 2s ease;
}

header.recultivation{
    background-image: url("../images/bg-new3.jpg"); 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    -webkit-transition:all 2s ease;
    -moz-transition:all 2s ease;
    -o-transition:all 2s ease;
    -ms-transition:all 2s ease;
    transition:all 2s ease;
}

footer {
    background-image: url("../images/bg_footer.png");
    height: 1070px;
    background-position: top; 
    background-repeat: no-repeat; 
    background-size: cover; 
}


/****************************************
************** CLASIC CSS ***************
****************************************/

div#preload{
    display: none;
}

button:focus{
    outline:none;
}

.header-toogle-button{
    cursor: pointer;
    z-index: 1;
}

.search-button-responsive-menu{
    width: 20%;
    display: inline-block;
    position: absolute;

}

.collapse.navbar-collapse.show{
    right: 0px;
}

.menu-collapse.show, #navcol-1{
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    width: 100%;

}

.navbar-toggler:focus{
    outline-color: rgba(0, 0, 0, 0) !important;
}

.menu-collapse.show{
    display: flex;
    position: absolute;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    padding-top: 20px;
}

#navcol-1{
    top:68px !important;
}

.navbar-toggler{
    display: flex;
    border:none;
    border-color: none;
    outline: none !important;
}

.form-group.search-input{
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
    width: 80%;
    display: inline-block;
    bottom: 0px;
}

.form-control.search-input{
    padding-left: 15px;
    padding-right: 15px;
    height: 58px!important;
}

.form-control.search-input:focus{
    box-shadow: none;
}


.hidden{
    display: none !important;
}

.sd-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;    
}

.underline-first {
    border-bottom: 3px solid #00a9a7;
    width: 65%;	
 }

.sd-container-aktuality{
    padding-left: calc(50% - 120px);
    padding-right: auto;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.sd-container-aktuality-flex{
    width:100%;
}

.sd-container-aktuality-flex>div{
    width:100%;
}

/* a:first-child .sd-article{
    background-image: url("../images/aktuality-clanky-foto-first.png");
} */

.sd-article{
    background-image: url("../images/aktuality-clanky-foto.jpg");
    background-position: top; 
    background-repeat: no-repeat; 
    background-size: cover;
    width:240px; 
    height: 350px; 
    margin-right:90px; 
    margin-bottom:50px; 
    position: relative;
    display:inline-block;

}

.sd-article:hover{
    cursor: pointer;
}

.sd-article:hover .sd-article-body{
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.sd-article-body{
    background-color:white; 
    width:100%; 
    height: 175px;
    position: absolute;
    bottom: 0;
    color: black;
    box-shadow: 0px 7px 12px 0px #d1d1d1;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sd-article-content{
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 18px;
    max-height: 72px;  /* line-height x max-lines*/
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 4;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.sd-article-title{
    font-family: Tahoma;
    font-size: 18px;
    line-height: 22px;
    height: 43px;
    max-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.sd-article-link{
    position: absolute;
    bottom: 15px;
    font-size: 14px;
    font-weight: 600;
}

.sd-container-category{
    margin-top: -270px;
}


.sd-category-article{
    background-image: url("../images/category-clanek.jpg");
    background-position: top; 
    background-repeat: no-repeat; 
    background-size: cover;
    width:100%; 
    height: 440px; 
    margin-bottom:50px; 
    position: relative;
    display:inline-block;
}

.sd-container-aktuality.category{
    padding-left: 0;
}

.sd-category-article:hover{
    cursor: pointer;
}

.sd-category-article:hover .sd-category-article-body{
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

.sd-category-article .sd-category-article-body{
    height: 220px;
}

.sd-category-article-body{
    background-color:white; 
    width:100%; 
    height: 175px;
    position: absolute;
    bottom: 0;
    color: black;
    box-shadow: 0px 7px 12px 0px #d1d1d1;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sd-category-article .sd-article-content{
    line-height: 18px;
    max-height: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 6;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.footer-contact-height {
    height: 820px!important;
}

.sd-text-primary {
    color: var(--sd-primary-color);
}

.detail-contact-text-position {
    text-align: left;
}

#about {
    text-align: center;
}

#detail-contact {
    background-color: #001c18;
    color: white;
    position: relative;
    z-index: 1000;
}

.sd-contact-hr {
    height: 1px;
    background-color: #2d4441;
    width: 100%;
}

.form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: 0px 7px 12px 0px #f1f1f1;
}


.radius-0 {
    border-radius: 0!important;
}

.sd-pointer {
    cursor: pointer;
}


.contact-header {
    height: 600px!important;
}

.sd-navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;    
    padding: 0;
}

.sd-navbar-brand {
    display: inline-block;
}

.sd-navbar-brand img {
    width: 150px;
}

.sd-search-button {
    color: white;
    width: 100px;
    align-items: baseline;
    display: flex;
    justify-content: center;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.footer-logo {
    justify-content: center;
}

#navcol-1 {
    position: absolute;
    top: 70px;
    right: 0px;
    z-index: 1000;
}

#navcol-1 ul li a {
    color: white;
    font-weight: 500;
}

#navcol-1 ul li a:hover {
    color: white;
    font-weight: bold;
}

.sd-btn-square {
    border-radius: 0%;
    color: white;
    border: none;
}

.btn:hover {
    color: white;
}

.no-radius {
    border-radius: 0!important;
}

.sd-btn-primary {
    background-color: var(--sd-primary-color);    
    padding: 17px 42px;
}

.navbar-expand-md .navbar-nav .nav-item .nav-link, .navbar-collapse .navbar-nav .nav-item .nav-link{
    font-size: 0.8rem;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

#sub-header {
    color: white;
    margin: 30px 0px;
}

.sub-header-sub-caption, .who-we-are-sub-caption {
    font-family: RobotoRegular;
    font-size: 1.5rem;
}

.sub-header-sub-caption {
    margin-bottom: 1rem;
}

.sub-header-caption {
    font-family: Tahoma;
    font-size: 3rem;
}

.sub-header-text, .who-we-are-text {
    font-family: RobotoRegular;
    font-size: 20px;
}

.sub-header-more-btn img, .who-we-are-more-btn img {
    width: 180px;
}

.sub-header-more-btn {
    background-image: url("../images/Zjistit-vice-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 50px;
}

.who-we-are-more-btn {
    background-image: url("../images/Zjistit-vice-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 80px;
    z-index: 1000;
    padding-left: 0rem;
    justify-content: center;
}

.who-we-are-more-btn-link{
    width: 100%;
}

.who-we-are-more-btn-link:hover{
    text-decoration: none;
}

.tile-link:hover{
    text-decoration: none;
}

.who-we-are-more-btn .btn-text{
    margin-left: -4.5rem;
}

.sub-header-more-btn, .who-we-are-more-btn, .home-btn {
    cursor: pointer;
}

.sub-header-chevrons img {
    width: 50px;
    height: 17px;
    display: inline-block;
}

.sub-header-chevrons {
    display: flex;
    position: relative;
}

.sub-header-chevrons .first {
    position: absolute;
    left: 0;
}

.sub-header-chevrons .second {
    position: absolute;
    left: 43px;
}

#pictured-navigation {
    position: absolute;
    top: -1170px;
    left:50%;
    padding-left: 15px;
    padding-right: 15px;
    width: 93%;
    transform: translate(-50%, 0);
}

#who-we-are {
    margin-top: 1070px;
}

.who-we-are-caption {
    font-family: Tahoma;
    font-size: 3rem;
}

.who-we-are {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-mask-image: url("../images/dlazdice4.svg");
    mask-image: url("../images/dlazdice4.svg");
    mask-size: cover;
    mask-position: right;
    width: 100%;
    position: relative;
}

#pictured-navigation img {
    width: 97%;
    cursor: pointer;
}

.no-padding {
    padding: 0px;
}

#secondary-container {
    background-image: url("../images/pruh-pozadi.png");    
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 210px;
    height: 210px;
    width: 100%;
    margin-top: -90px;
    position: relative;
    z-index: -1;
}

.preloader-secondary-container img{
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    object-fit: cover;
}

.preloader-secondary-container div{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}

#actual-xs {
    margin-bottom: 200px;
}

footer .sd-container {
    position: relative;
}

.actual {
    background-image: url("../images/aktuality-tmave.jpg");    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    color:white;
    position: relative;
}

.actual-more-btn, .into-deep-more-btn {
    height: 50px;
    width: 100%;
    background-color: var(--sd-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.actual-paging {
    height: 80px;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
}

.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: none;
}

.p-50 {
    padding: 50px;
}

.into-deep {
    background-image: url("../images/hornickeListyBG-tmave.jpg");    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    color: white;
    position: relative;
}

.actual-into-deep-caption {
    font-size: 2.5rem;
    font-family: Tahoma;
}

.actual-into-deep-subcaption {
    font-size: 1.5rem;
    font-family: Tahoma;
}

.actual-into-deep-text {
    font-size: 1rem;
    line-height: 24px;
    max-height: 144px;  /* line-height x max-lines*/
    overflow: hidden;
    text-overflow: ellipsis;
    max-lines: 6;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

#actual-into-deep {
    position: absolute;
    top: -150px;
    width: 94%;
    left: 50%;
    transform: translate(-50%, 0px);
}

.who-we-are-paging {
    display: none;
}

#main-container .sd-container {
    position: relative;
}

#main-container .sd-container-aktuality{
    position: relative;
}

.p-0-50 {
    padding: 0px 50px;
}

.w-80-auto {
    width: 80%;
    margin: 0 auto;
}

.w-90-auto {
    width: 90%;
    margin: 0 auto;
}

.m-10-negate {
    margin-top: -10%!important;
}

.w-90-auto {
    width: 90%;
    margin: 0 auto;
}

.w-93-auto {
    width: 93%;
    margin: 0 auto;
}

.mb20 {
    margin-bottom: 20px;
}

.mt20 {
    margin-top: 20px;
}

.sd-mt-4 {
    margin-top: 4.5rem!important;
}

.footer-top-padding {
    padding-top: 330px;
}

.footer-top-padding-contact {
       padding-top: 50px!important;
}

.hr-white {
    border-color: white!important;
}

#footer-content {
    color: white;
}

#footer-content img {
    width: 200px;
    padding: 10px 0px;
}

.mt-4-v2 {
    margin-top: 1.5rem;
}

.sd-hidden-xs {
    display: none !important;
}

.sd-visible-xs {
       display: block;
}	


.pic-nav {
    height: 285px;
    color: white;
    letter-spacing: 2px;
    font-size: 1.3rem;
    cursor: pointer;
}

.pic-nav-padding {
    padding: 10px;
}

.first-pic-nav {
    background-image: url("../images/rozcestnik1.jpg");    
    background-position-y: 60%;
    background-position-x: 60%;
    background-repeat: no-repeat;
    background-size: cover;    
    width: 100%;
    -webkit-mask-image: url("../images/dlazdice1.svg");
    mask-image: url("../images/dlazdice1.svg");
    mask-size: cover;
}

.second-pic-nav {
    background-image: url("../images/rozcestnik2.jpg");    
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 285px;
    width: 100%;
}

.third-pic-nav {
    background-image: url("../images/rozcestnik3.jpg");    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 285px;
    width: 100%;
}

.fourth-pic-nav {
    background-image: url("../images/rozcestnik4.jpg");    
    background-position-y: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    height:285px;
    width: 100%;
    -webkit-mask-image: url("../images/dlazdice4.svg");
    mask-image: url("../images/dlazdice4.svg");
    mask-size: cover;
    mask-position: right bottom;
}

.who-we-are-gallery img{
    width: 100%;
}

.sub-header-sub-caption-visibility {
       display: none!important;
    }

    .footer-hr-visibility{
       display: block;
    }

    .footer-company-name-text-position {
       text-align: center;
    }

    .hr-white-footer{
        border-color: white!important;
        width:100%;
    }

    .trapezoid {
        width: 200px;
        text-align: center;
        height: 0;
        position: relative;
        border-right: 35px solid transparent;
        border-top: 40px solid #00a9a7;
        box-sizing: content-box;
        background: none;
        border-bottom: none;
        border-left: none;
    }

     .trapezoid span {
       position: absolute;
       top: -30px;
       left: 15%;
       color: #fff;
     }

     .notFound-sub-header-caption{
        font-size: 10rem !important;
     }

     .error-image{
         width: 250px;
     }

.home-btn img {
        width: 200px;
    }
    
    .home-btn {
        background-image: url("../images/Zjistit-vice-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 80px;
        z-index: 1000;
        padding-left: 0rem;
        justify-content: center;
    }
    
    .home-btn-link{
        width: 100%;
    }
    
    .home-btn .btn-text{
        margin-left: -4.5rem;
    }

    .into-deep{
        padding-top: 10px !important;
    }

     /********* DETAIL ***************/

  .detail-header {
    background-image: url("../images/bg-new2.jpg")!important;
    background-position-x: center;
    background-position-y: 35%;   
    min-height: 400px!important;
  }

  .detail-sub-header-caption {
      font-size: 3rem;
  }

  .detail-title h2 {
    font-size: 1.5rem!important;
  }

  .detail-logo {
      width: 165px!important;
  }

  .detail-content-padding {
      padding: 1rem;
  }

  .detail-scroll-gallery img, .wp-block-table table {
    width: 100%;
  }

  .slick-slider.slick-initialized{
    width: 100%;
  }

  .detail-text>img, .detail-text>.wp-block-image>img {    
    -webkit-mask-image: url("../images/dlazdice4.svg");
    mask-image: url("../images/dlazdice4.svg");
    mask-size: cover;
    mask-position: right;
    width: 100%;
  }

  .detail-text>img, .detail-text>.wp-block-image.no-crop-img>img {    
    -webkit-mask-image: none;
    mask-image: none;
    mask-size: cover;
    mask-position: right;
    width: 100%;
    height: auto;
  }

  .wp-block-gallery {
      display: flex;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
  }

  .detail-text>.wp-block-gallery>ul {    
    list-style-type: none;
    padding-inline-start: 0;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom:0;
    margin-block-end: 0;
  }

  .detail-text>.wp-block-gallery li {    
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    border: 1px solid #000;

  }

  .detail-text>.wp-block-gallery>ul>li>figure>a>img {    
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 300px;
    vertical-align: baseline;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }

  .detail-text>.wp-block-gallery>ul>li:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
}

  .detail-text p {
      margin: 20px 0px;
  }

  .detail-read-more{
      cursor: pointer;
  }

  .wp-block-file__button{
      display: none;
  }

  .wp-block-file{
      margin-bottom: 20px;
  }

  .wp-block-columns{
      display: flex;
  }

  .wp-block-column{
      width: 50%;
  }

  .wp-block-button__link{
    width: 240px;
    height: 350px;
    background-color: rgba(0, 0, 0, 0);
    display: table-cell;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: white;
    vertical-align: middle;
    font-family: RobotoRegular;
  }

  .wp-block-button__link:hover{
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    text-decoration: none;
  }
  .wp-block-button__inner-button{
      display: none;
  }

  .wp-block-button__link:hover>.wp-block-button__inner-button{
    height: 50px;
    width: 180px;
    margin-top: 20px;
    margin-bottom: -70px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--sd-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
  }

  .wp-block-button__link>.year-caption{
    font-size: 14px;
    font-weight: 400;
  }

  .wp-block-button__link>.year-content{
    font-size: 45px;
    font-weight: 600;
  }

  .wp-block-button__link:hover>.wp-block-button__inner-button>i{
    margin-left: 10px;
    margin-top: -2px;
  }

  .wp-block-button{
    background-image: url("../images/aktuality-clanky-foto.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 240px;
    height: 350px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 50px;
    position: relative;
    display: block;
  }

  .wp-block-embed-youtube iframe{
      width: 100%;
  }

  /********* AKTUALITY ***************/

  .aktuality-header {
    background-image: url("../images/bg-new3.jpg");
    height: 700px;
    background-position: 0 -100px; 
    background-repeat: no-repeat; 
    background-size: cover; 
  }

  .category-header{
    background-image: url("../images/bg-new3.jpg");
    height: 850px; 
    background-repeat: no-repeat; 
    background-size: cover; 
  }

.table-with-photos td{
    border: 1px solid lightgray;
    padding: 10px;
}

/****************************************
***************** MEDIA *****************
****************************************/

@media (min-width: 500px){

    .sd-category-article{
        background-image: url("../images/category-clanek.jpg");
        background-position: top; 
        background-repeat: no-repeat; 
        /* background-size: 360px 220px;  */
        background-size: cover;
        width:360px; 
        height: 440px; 
        margin-left: -14%;
        margin-right:auto;
        margin-bottom:50px; 
        position: relative;
        display:inline-block;
    }

    .sd-container-aktuality.category{
        padding-left: calc(50% - 120px);
    }

    .into-deep{
        padding-top: 50px !important;
    }
}

@media (min-width: 768px) {
    header {
        height: 600px;
    }

    footer {
        height: 740px;
        font-size: 0.9rem;
    }

    .li-search-button-responsive-menu{
        display: none;
    }

    .menu-collapse.show, #navcol-1{
        background-color: rgba(0, 0, 0, 0);
    }
    
    .menu-collapse.show{
        display: block;
        position: unset;
    }
    
    #navcol-1 {            
        top: 5px !important;
        right: 115px;
        z-index: 1000;
    }

    .form-group.search-input{
        width:300px;
        display: flex;
        margin-left: -200px;
        height: 40px;

    }
    
    .form-control.search-input{
        width:100%;
        height: 40px !important;
    }

    .detail-scroll-gallery img {
        width: 200px;
    }

    .detail-content-padding {
        padding: 2rem 5rem;
    }

    #pictured-navigation {
        position: absolute;
        top: -1170px;
        left:15px;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
        width: 93%;
        transform: none;
    }

    .first-pic-nav {
        -webkit-mask-image: url("../images/dlazdice1.svg");
        mask-image: url("../images/dlazdice1.svg");
        mask-size: cover;
    }

    .fourth-pic-nav {
        -webkit-mask-image: none;
        mask-image: none;
    }

    .cta4{
        background-image: none;
        border: white solid 3px;
        height: 75px;
    }
    
    .cta4:hover{
        cursor: pointer;
    }
    
    .cta4-active{
        background-image: none;
        background-color: var(--sd-primary-color);
        height: 75px;
    }

    .sd-visible-xs {
        display: none !important;
    }

    .who-we-are-gallery {
        display: none;
    }

    #actual-xs {
        display: none;
    }

    .actual-xs-footer {
        display: none;
    }

    .detail-contact-text-position {
               text-align: right;
           }

           .contact-secondary-container {
               margin-top: -220px!important;
           }

           .footer-company-name-text-position {
               text-align: right;
           }

           .footer-hr-visibility{
               display: none;
           }

           .sub-header-sub-caption-visibility {
               display: block!important;
           }

           .footer-contact-height {
               height: 465px!important;
           }

           .contact-header {
               height: 500px!important;
           }

    #footer-content img {
        width: 150px;     
    }

    .footer-logo {
        justify-content: space-between;
    }

    .sd-container {
        width: 750px;
    }

    .aktuality-header {
        height: 500px;
      }

      .category-header {
        height: 700px;
      }

      .eu-header{
          height: 800px !important;
      }

    .sd-container-aktuality{
        width: 750px;
        padding-left: 90px;
    }

    .sd-container-aktuality.category{
        padding-left: 90px;
        width: 750px;
    }

    .sd-container-aktuality-flex{
        width:100%;
    }

    .sd-article{
        margin-right:90px; 
    }

    .sd-category-article{
        margin-left: 15.9%;
        margin-right:auto;
    }

    .pic-nav {
        font-size: 1rem;
    }
    
    .pic-nav-padding {
        padding: 5px;
    }

    .sd-navbar-brand img {
        width: 180px;
    }

    .sub-header-caption {
        font-size: 3.5rem;
    }

    .navbar-expand-md .navbar-toggler {
        display: inline-block;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }

    .sd-search-button {
        display: inline-block;
    }

    .actual-more-btn, .into-deep-more-btn {
        height: 50px;
        width: 180px;
        background-color: var(--sd-primary-color);
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    #pictured-navigation img {
        width: 180px;
    }

    #pictured-navigation {
        width: 720px;
        top: -280px;
    }

    #sub-header {
        margin: 30px 0px;
    }

    .sd-mt-4 {
        margin-top: 2.5rem!important;
    }

    .who-we-are {
        height: 250px;
    }

    #who-we-are {
        margin-top: 200px;
    }

    .who-we-are-caption {
        font-size: 2rem;
    }

    .who-we-are-text{
        font-size: 13px;
    }

    #actual-into-deep {
        width: 720px;
    }

    .mt-4-v2 {
        margin-top: 0;
    }

    .actual-into-deep-caption {
        font-size: 1.75rem;
    }

    .actual-into-deep-subcaption {
        font-size: 1rem;
    }

    .sd-hidden-xs {
        display: block !important;
    }

    .d-flex.sd-hidden-xs {
        display: flex !important;
    }

    .pic-nav {
        height: 180px;
    }

    .sub-header-sub-caption, .who-we-are-sub-caption {
        font-family: RobotoRegular;
        font-size: 0.8rem;
    }

    .sub-header-text, .who-we-are-text {
        font-family: RobotoRegular;
        font-size: 15px;
    }

    .who-we-are-more-btn {
        width: 200px;
        height: 50px;
        justify-content: start;
    }
    
    .who-we-are-more-btn .btn-text{
        padding-left: 1.5rem;
        margin-left: 0;
    }

    #secondary-container {   
        background-image: url("../images/pruh-bg-body-pruhledne.png");     
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 385px;
        margin-top: -80px;
    }

    .actual-paging {
        height: 50px;
        width: 180px;
        right: 180px;
        font-size: 13px;
        position: absolute;
        bottom: 0;
    }

    .who-we-are-paging {
        height: 50px;
        width: 180px;
        left: 0;
        font-size: 13px;
        position: absolute;
        bottom: 0;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        z-index: 1000;
    }

    .home-btn {
        width: 240px;
        height: 50px;
        justify-content: start;
    }
    
    .home-btn .btn-text{
        padding-left: 1.5rem;
        margin-left: 0;
    }

    .error-image{
        float: left;
    }

    .wp-block-button{
        margin-left: 19px;
        margin-right: 19px;
        display: inline-block;
        transform: none;
      }
}

@media (min-width: 992px) {
    .sd-container {
        width: 970px;
    }

    .sd-container-aktuality{
        width: 970px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .sd-container-aktuality.category{
        padding-left: 15px;
        padding-right: 15px;
        width: 970px;
    }

    .sd-article{
        margin-right:35px; 
        margin-left:35px; 

    }

    a:nth-child(odd) .sd-category-article{
        margin-left: 10%;
        margin-right:10px;
    }
    a:nth-child(even) .sd-category-article{
        margin-left: 0;
    }

    .wp-block-gallery {
        width: 150%;
        margin-left: -25%;
        margin-right: -25%;
    }

    .detail-content-padding {
        padding: 2rem 10rem;
    }

    .detail-scroll-gallery img {
        width: 300px;
    }
    
    .pic-nav-padding {
        padding: 30px;
    }

    .actual-into-deep-caption {
        font-size: 2.5rem;
    }

    .actual-into-deep-subcaption {
        font-size: 1.5rem;
    }

    .navbar-expand-md .navbar-nav .nav-item .nav-link {
        padding-right: 2rem!important;
        padding-left: 2rem!important;
        font-size: 0.8rem;
    }

    .navbar-expand-md .navbar-collapse {
        display: none!important;
    }

    .sd-navbar-brand img {
        width: 175px;
    }

    #navcol-1 ul li a {
        letter-spacing: 1px;
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    #pictured-navigation {
        width: 940px;
        top: -340px;
    }

    #pictured-navigation img {
        width: 235px;
    }

    #sub-header {
        margin: 40px 0px;
    }

    #actual-into-deep {
        width: 940px;
    }

    .who-we-are {
        height: 330px;
    }

    #who-we-are {
        margin-top: 240px;
    }

    .mt-4-v2 {
        margin-top: 1.5rem!important;
    }

    .pic-nav {
        height: 235px;
    }

    .wp-block-button{
        margin-left: 34px;
        margin-right: 34px;
      }

      
}

@media (min-width: 1200px) {

    .navbar-expand-md .navbar-nav .nav-item .nav-link{
        padding-right: 3rem!important;
        padding-left: 3rem!important;
        font-size: 0.8rem;
    }

    .sd-container {
        width: 1170px;
    }

    .sd-container-aktuality{
        width: 1170px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .sd-container-aktuality.category{
        padding-left: 0px;
        padding-right: 0px;
        width: 1170px;
    }


    .sd-article{
        margin-left: 2.8%;
        margin-right: 1%;
    }

    a:nth-child(3n+1) .sd-category-article{
        margin-left: 2.8%;
        margin-right:0;
    }
    a:nth-child(3n+2) .sd-category-article{
        margin-left: 10px;
        margin-right:10px;
    }
    a:nth-child(3n+3) .sd-category-article{
        margin-left: 0;
        margin-right:10px;
    }

    .detail-content-padding {
        padding: 2rem 14rem;
    }

    .pic-nav {
        font-size: 1.3rem;
    }
    
    .pic-nav-padding {
        padding: 35px;
    }

    .sd-navbar-brand img {
        width: 200px;
    }

    #sub-header {
        margin-top: 30px;
    }
    
    #pictured-navigation img {
        width: 285px;
    }

    #pictured-navigation {
        top: -400px;
        width: 1140px;
    }
    
    #who-we-are {
        margin-top: 300px;
    }

    .sub-header-caption {
        font-size: 5.5rem;
    }

    .sub-header-text {     
        font-size: 0.9rem;
    }

    .who-we-are-text {
        font-size: 1rem;
    }

    #actual-into-deep {
        width: 1140px;
    }

    .who-we-are {
        height: 400px;
    }

    .pic-nav {
        height: 285px;
    }

    .wp-block-button{
        margin-left: 52px;
        margin-right: 52px;
      }
}